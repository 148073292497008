import { trpc } from "@api/client"
import { IFieldConfig } from "../../../../../types/documents"
import { createContext, useContext, useState, ReactNode, useMemo } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { v4 } from "uuid"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"
import { useMutation, useQuery } from "@tanstack/react-query"
import { api } from "@api/index"
import dispatchToast from "@utils/dispatchToast"

const useCreatSectionPageContextValue = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { selectedCompany } = useSelectedCompany()
  const [step, setStep] = useState(0)
  const [sectionName, setSectionName] = useState<string>("")
  const [sectionDescription, setSectionDescription] = useState<string>("")
  const [sectionNameInputErrorMessage, setSectionNameInputErrorMessage] =
    useState<string>()
  const [fields, setFields] = useState<IFieldConfig[]>([])
  const formGroupId = useMemo(() => v4(), [])

  const { flowId, id: baseOrExtensionId } = useParams()

  const relatedFormConfigPagePath = useMemo(
    () => `/flows/settings/${flowId}/documents/${baseOrExtensionId}`,
    [flowId, baseOrExtensionId],
  )

  const selectedSection = useMemo(
    () => searchParams.get("selectedSection") as "employee" | "dependent",
    [searchParams],
  )

  const {
    mutate: sendFormConfigExtension,
    isLoading: isSendFormConfigExtensionLoading,
  } = useMutation(api.mutation.hiring.modelDocuments.sendFormConfigExtension, {
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: "Seção criada com sucesso!",
      })

      navigate(relatedFormConfigPagePath)
    },
  })

  const goToAddFieldStep = () => {
    if (!sectionName) {
      setSectionNameInputErrorMessage("Campo obrigatório")
      return
    }

    setStep(1)
  }

  const { data: getCustomFieldsData, isLoading: isGetCustomFieldsLoading } =
    trpc.customField.getCustomFields.useQuery(
      { companyId: selectedCompany.id },
      { refetchOnWindowFocus: false },
    )

  const { data: getDocumentTypesData, isLoading: isGetDocumentTypesLoading } =
    trpc.documentType.getDocumentTypesProcedure.useQuery(
      {
        companyId: selectedCompany.id,
      },
      { refetchOnWindowFocus: false },
    )

  const { data: formConfig } = useQuery(
    ["form-config", baseOrExtensionId],
    () =>
      api.query.hiring.modelDocuments.getFormConfigByExtensionOrBase(
        baseOrExtensionId || "",
      ),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onError: () => {
        navigate("/flows/hiring")
        dispatchToast({
          type: "error",
          content: "Houve um problema ao buscar dados do documento",
        })
      },
    },
  )

  const formConfigCustomFieldIdSet = useMemo(
    () =>
      new Set(
        formConfig?.formGroups
          .filter(({ category }) => category === selectedSection)
          .map(({ fields }) => fields || [])
          .flat()
          .map(({ customFieldId }) => customFieldId) || [],
      ),
    [formConfig, selectedSection],
  )

  const createSection = () => {
    if (!fields.length) {
      dispatchToast({
        type: "error",
        content: "Por favor, adicione pelo menos 1 campo na seção",
      })
      return
    }

    const formGroups = formConfig?.formGroups || []

    sendFormConfigExtension({
      baseId: formConfig?._id as string,
      companyId: selectedCompany.id,
      name: formConfig?.name as string,
      formGroups: [
        ...formGroups,
        {
          _id: formGroupId,
          title: sectionName,
          description: sectionDescription,
          fields,
          type: "base",
          category: selectedSection,
          enabled: true,
        },
      ],
    })
  }

  return {
    relatedFormConfigPagePath,
    step,
    setStep,
    sectionName,
    setSectionName,
    sectionDescription,
    setSectionDescription,
    sectionNameInputErrorMessage,
    setSectionNameInputErrorMessage,
    goToAddFieldStep,
    formGroupId,
    setFields,
    fields,
    getCustomFieldsData,
    isGetCustomFieldsLoading,
    getDocumentTypesData,
    isGetDocumentTypesLoading,
    isSendFormConfigExtensionLoading,
    createSection,
    formConfigCustomFieldIdSet,
  }
}

const CreateSectionPageContextProvider = ({
  children,
}: {
  children: ReactNode
}) => (
  <CreateSectionPageContext.Provider value={useCreatSectionPageContextValue()}>
    {children}
  </CreateSectionPageContext.Provider>
)

const CreateSectionPageContext = createContext<
  ReturnType<typeof useCreatSectionPageContextValue> | undefined
>(undefined)

const useCreateSectionPageContext = () => {
  const context = useContext(CreateSectionPageContext)
  if (context === undefined) {
    throw new Error(
      "useCreateSectionPageContext must be used within an CreateSectionPageContextProvider",
    )
  }
  return context
}

export { CreateSectionPageContextProvider, useCreateSectionPageContext }
