import React from "react"
import {
  Checkbox,
  DatePicker,
  Dropzone,
  MultiSelect,
  SelectField,
  TextField,
  TimePicker,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  IFieldConfig as FieldType,
  ComponentEnum as ComponentType,
  ComponentEnum as ComponentEnum,
} from "../../../types"

import { Container } from "./styles"

type ComponentData = { [key: string]: React.ReactNode }

export type RenderFieldProps = {
  field: FieldType
  style?: React.CSSProperties
}

export const RenderField = ({ field, style }: RenderFieldProps) => {
  const components: ComponentData = {
    [ComponentType.ShortText]: (
      <TextField
        name={field.customFieldId}
        label={field.placeholder}
        onChange={(e) => console.log(e)}
        fullWidth={true}
        required={field.fieldRule?.required}
        disabled={!field.enabled}
        type={"text"}
      />
    ),
    [ComponentType.Number]: (
      <TextField
        name={field.customFieldId}
        label={field.placeholder}
        onChange={(e) => console.log(e)}
        fullWidth={true}
        required={field.fieldRule?.required}
        disabled={!field.enabled}
        type={"number"}
      />
    ),
    [ComponentType.LongText]: (
      <TextField
        name={field.customFieldId}
        label={field.placeholder}
        fullWidth={true}
        type={"text"}
        required={field.fieldRule?.required}
        onChange={(e) => console.log(e)}
        disabled={!field.enabled}
        multiline={true}
        rows={3}
      />
    ),
    [ComponentType.Select]: (
      <SelectField
        name={field.customFieldId}
        label={field.placeholder}
        fullWidth={true}
        onSelectChange={(e) => console.log(e)}
        required={field.fieldRule?.required}
        disabled={!field.enabled}
        options={
          field.options
            ? field.options.map((option) => ({ label: option, value: option }))
            : []
        }
      />
    ),
    [ComponentType.MultiSelect]: (
      <MultiSelect
        renderInput={() => null}
        label={field.placeholder}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        options={
          field.options
            ? field.options.map((option) => ({ label: option, value: option }))
            : []
        }
        onSelectChange={(_, value) => {
          console.log(value)
        }}
        disabled={!field.enabled}
      />
    ),
    [ComponentType.DatePicker]: (
      <DatePicker
        label={field.placeholder}
        onDateChange={(e) => console.log(e)}
        required={field.fieldRule?.required}
        disabled={!field.enabled}
        fullWidth={true}
      />
    ),
    [ComponentType.TimePicker]: (
      <TimePicker
        label={field.placeholder}
        onChange={(value) => console.log(value)}
        disabled={!field.enabled}
        value={null}
      />
    ),
    [ComponentType.Link]: (
      <TextField
        name={field.customFieldId}
        label={field.placeholder}
        onChange={(e) => console.log(e)}
        fullWidth={true}
        required={field.fieldRule?.required}
        disabled={!field.enabled}
        type={"text"}
      />
    ),
    [ComponentType.Checkbox]: (
      <div>
        <Checkbox
          value={null}
          onChange={(e) => console.log(e)}
          required={field.fieldRule?.required}
          disabled={!field.enabled}
        />
        {field.placeholder}
      </div>
    ),
    [ComponentType.Attachment]: (
      <Dropzone
        title={field.placeholder}
        accept={["png", "jpg", "jpeg"]}
        onChange={(e) => console.log(e)}
      />
    ),
  }

  return (
    <Container style={style}>
      {components[field.component as ComponentEnum]}
    </Container>
  )
}
