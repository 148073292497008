import { gql } from "graphql-request"
import { request } from "../client"
import { IFormConfig } from "../../types"

const GET_FORMCONFIG = gql`
  query GetFormConfig($params: GetFormConfigInput) {
    getFormConfig(params: $params) {
      _id
      baseId
      companyId
      extensionId
      formGroups {
        _id
        category
        description
        enabled
        fields {
          component
          enabled
          fieldId
          customFieldId
          fieldRule {
            fieldId
            required
          }
          fieldType
          order
          placeholder
          formGroupId
          type
        }
        fillInstruction
        order
        title
        type
      }
      name
      type
    }
  }
`

export const getFormConfig = async (params: {
  formConfigId: string
  extensionId?: string
}) => {
  const { getFormConfig } = await request(GET_FORMCONFIG, { params })
  return getFormConfig as IFormConfig
}
