import { useTranslation } from "react-i18next"
import { generatePath, useNavigate } from "react-router-dom"

import { Flow, ResignationCard } from "../../../../types"
import ProfilePicture from "../../ProfilePicture"

import {
  CandidateContainer,
  IconWrapper,
  InfoHeader,
  ProfileTitle,
} from "./styles"

import { IconButton } from "@flash-tecnologia/hros-web-ui-v2"

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { externalRoutes } from "src/routes/supportedExternalRoutes"
import { request } from "../../../../api/client"
import { RESIGNATION_MOVE_COLUMN } from "../../../../api/mutations/resignation-card-move-column"
import dispatchToast from "../../../../utils/dispatchToast"
import InfoList, { InfoWrapper } from "./InfoList"

const ResignationInformationTab = ({
  card,
  flow,
}: {
  card: ResignationCard
  flow: Flow
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const { mutate: moveColumn } = useMutation(
    async ({ params }: { params: any }) => {
      await request(RESIGNATION_MOVE_COLUMN, { params })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["flows"])
      },
    },
  )

  const redirect = "/flows/resignation"

  const employeeInfo: React.ComponentProps<typeof InfoList>["data"] = [
    {
      value: card.employee?.name || "Não cadastrado",
      text: t("personalInfo.name"),
      icon: "IconUser",
    },
    {
      value:
        card.employee?.corporateEmail ||
        card.employee?.email ||
        "Não cadastrado",
      text: t("personalInfo.email"),
      icon: "IconMail",
    },
    {
      value:
        card.employee?.phoneNumber?.replace(
          /(\d{2})(\d{2})(\d{5})(\d{4})/,
          "$1 $2 $3-$4",
        ) || "Não cadastrado",
      text: t("personalInfo.phone"),
      icon: "IconPhone",
    },
    {
      value:
        card.employee?.documentNumber?.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3-$4",
        ) || "Não cadastrado",
      text: t("personalInfo.cpf"),
      icon: "IconId",
    },
  ]

  const archiveCandidate = () => {
    if (!flow.columns) {
      dispatchToast({
        content: "Houve um problema ao arquivar o candidato",
        type: "warning",
      })
      return
    }
    const { _id: archivedColumn } =
      flow.columns.find((column) => column.name === "Arquivado") || {}

    if (!archivedColumn) {
      dispatchToast({
        content: "Houve um problema ao arquivar o candidato",
        type: "warning",
      })
      return
    }

    moveColumn(
      {
        params: {
          flowCardId: card._id,
          newColumnId: archivedColumn,
          newPosition: 0,
          version: card.version,
        },
      },
      {
        onSuccess: () => {
          dispatchToast({
            content: "Candidato arquivado com sucesso!",
            type: "success",
          })
          queryClient.invalidateQueries(["flows", card.flowId])
          navigate(redirect)
        },
      },
    )
  }

  const handleEditCandidate = () => {
    navigate(
      generatePath(externalRoutes.employeesProfile.path, {
        employeeId: card.employee?.id,
      }),
    )
  }

  return (
    <CandidateContainer>
      <InfoHeader>
        <ProfilePicture
          userName={card.employee?.name || "Não cadastrado"}
          src={card.employee?.profilePicture}
          alt="Foto de perfil do usuario"
          style={{ marginTop: 30 }}
        />

        <ProfileTitle>{card.employee?.name}</ProfileTitle>

        <IconWrapper>
          <IconButton
            icon="IconPencil"
            variant="line"
            size="small"
            onClick={handleEditCandidate}
            style={{ margin: "0 5px" }}
          />
          <IconButton
            icon="IconArchive"
            variant="line"
            size="small"
            onClick={archiveCandidate}
            style={{ margin: "0 5px" }}
          />
          <IconButton
            icon="IconShare"
            variant="line"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(window.location.href)
              dispatchToast({
                type: "success",
                content: "Link do candidato copiado",
              })
            }}
            style={{ margin: "0 5px" }}
          />
        </IconWrapper>
      </InfoHeader>

      <InfoWrapper>
        <InfoList data={employeeInfo} />
      </InfoWrapper>
    </CandidateContainer>
  )
}

export default ResignationInformationTab
