import {
  candidateValidateDocument,
  changeEmployeeInOrgchart,
  changeMultipleEmployeeInOrgchart,
  checkLeaderByEmployeeId,
  checklistTemplateAdd,
  checklistTemplateNew,
  checklistTemplateRemove,
  checklistTemplateUpdate,
  checklistToggleHiring,
  checklistToggleResignation,
  checklistUpdateHiring,
  checklistUpdateResignation,
  createBoardReport,
  createFormConfig,
  createHiringCard,
  createManyResignationCard,
  createMassCandidateProcess,
  createModelProposedLetter,
  createResignationCard,
  deleteModelProposedLetter,
  dependentValidateDocument,
  dismissCandidateProcess,
  duplicateModelProposedLetter,
  getMultipleS3File,
  getS3File,
  hiringChangeColumn,
  hiringPostComment,
  hiringUpdateCard,
  hiringUpdateCardStatus,
  interruptMassCandidateProcess,
  orderCardDelivery,
  removeFormConfig,
  renameFormConfig,
  replaceChildrenInOrgchart,
  resendAdmissionalExamRequest,
  resendDocumentsRequest,
  resendSignRequest,
  resetAso,
  resetContract,
  resignationPostComment,
  resignationUpdateCard,
  saveBenefits,
  saveWorkshift,
  sendAdmissionalExamRequest,
  sendDocumentsRequest,
  sendFormConfigExtension,
  sendHiringNextColumn,
  sendResignationSignRequest,
  sendSignRequest,
  sendSignRequestV2,
  signManyContracts,
  updateCandidateById,
  updateContract,
  updateModelProposedLetter,
  validateAsoDocument,
} from "./mutations"
import {
  findMassCandidateProcessByFlow,
  getAddMassCandidateModels,
  getAddressByZipCode,
  getAllModelProposedLetterByFlowId,
  getAllResignationCard,
  getAndValidateSigningCandidates,
  getBackboneSections,
  getCandidateBenefits,
  getChecklistTemplate,
  getCompanyBenefits,
  getCompanyContracts,
  getCompanyDeliveryAddresses,
  getConfirmationData,
  getContractById,
  getDeliveryCardOrder,
  getEmployeeByCompany,
  getEmployeeByPartialName,
  getFlowBigNumbers,
  getFlowByCompany,
  getFlowById,
  getFlowSubcategoryByFlowId,
  getFormConfig,
  getFormConfigByCompany,
  getFormConfigByExtensionOrBase,
  getHiringCardById,
  getLeadersFromOrgchart,
  getModelContractById,
  getModelProposedLetterById,
  getOnlyFlowById,
  getResignationCardById,
  getWorkshiftById,
  getWorkshifts,
  verifyModelContract,
} from "./queries"

const mutation = {
  hiring: {
    checklist: {
      templateNew: checklistTemplateNew,
      templateAdd: checklistTemplateAdd,
      templateRemove: checklistTemplateRemove,
      templateUpdate: checklistTemplateUpdate,
      toggleItem: checklistToggleHiring,
      updateItem: checklistUpdateHiring,
    },
    massCandidate: {
      createProcess: createMassCandidateProcess,
      interruptProcess: interruptMassCandidateProcess,
      dismissProcess: dismissCandidateProcess,
    },
    massSignature: {
      signManyContracts,
    },
    modelDocuments: {
      createFormConfig,
      removeFormConfig,
      renameFormConfig,
      sendFormConfigExtension,
    },
    modelProposedLetter: {
      create: createModelProposedLetter,
      update: updateModelProposedLetter,
      duplicate: duplicateModelProposedLetter,
      delete: deleteModelProposedLetter,
    },
    flow: {
      getFlowSubcategoryByFlowId,
    },
    card: {
      createCard: createHiringCard,
      updateCard: hiringUpdateCard,
      nextColumn: sendHiringNextColumn,
      postComment: hiringPostComment,
      updateStatus: hiringUpdateCardStatus,
      moveColumn: hiringChangeColumn,
      resetAso: resetAso,
    },
    contract: {
      resetContract,
      sendSignRequest,
      sendSignRequestV2,
      resendSignRequest,
      verifyModelContract,
    },
    sendAdmissionalExamRequest,
    resendAdmissionalExamRequest,
    updateCandidate: updateCandidateById,
    candidateValidateDocument,
    dependentValidateDocument,
    sendDocumentsRequest,
    resendDocumentsRequest,
    createBoardReport,
    validateAdmissionalExamDocument: validateAsoDocument,
  },
  resignation: {
    checklist: {
      toggleItem: checklistToggleResignation,
      updateItem: checklistUpdateResignation,
    },
    card: {
      postComment: resignationPostComment,
      createCard: createResignationCard,
      createManyCard: createManyResignationCard,
      updateCard: resignationUpdateCard,
    },
    contract: {
      update: updateContract,
      sendSignRequest: sendResignationSignRequest,
    },
  },
  helper: {
    getS3File,
    getMultipleS3File,
  },
  orgchart: {
    checkLeaderByEmployeeId,
    changeEmployeeInOrgchart,
    replaceChildrenInOrgchart,
    changeMultipleEmployeeInOrgchart,
  },
  workshift: {
    saveWorkshift,
  },
  cardDelivery: {
    orderCardDelivery: orderCardDelivery,
  },
  benefits: {
    saveBenefits,
  },
}

const query = {
  hiring: {
    card: {
      getHiringCardById,
      getResignationCardById,
      getAllResignationCard,
      getConfirmationData,
    },
    massCandidate: {
      getModels: getAddMassCandidateModels,
      findProcessByFlow: findMassCandidateProcessByFlow,
    },
    massSignature: {
      getAndValidateSigningCandidates,
    },
    flow: {
      getFlowById,
      getOnlyFlowById,
      getFlowByCompany,
      getFlowBigNumbers,
    },
    contract: {
      getContractById,
      getModelContractById,
    },
    modelChecklist: {
      getChecklistTemplate,
    },
    modelDocuments: {
      getFormConfigByCompany,
      getFormConfig,
      getFormConfigByExtensionOrBase,
    },
    modelProposedLetter: {
      getById: getModelProposedLetterById,
      getByFlowId: getAllModelProposedLetterByFlowId,
    },
  },
  employee: {
    getEmployeeByPartialName,
    getBackboneSections,
    getEmployeeByCompany,
  },
  company: {
    getDeliveryAddresses: getCompanyDeliveryAddresses,
    getWorkshifts,
    getBenefits: getCompanyBenefits,
    getCompanyContracts,
  },
  orgchart: {
    getLeadersFromOrgchart,
  },
  workshift: {
    getWorkshiftById,
  },
  cardDelivery: {
    getDeliveryCardOrder,
    getAddressByZipCode,
  },
  benefits: {
    getCandidateBenefits,
  },
}

export const api = {
  mutation,
  query,
}
