import {
  Button,
  Icons,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"
import { useNavigate } from "react-router-dom"
import { IFormConfig } from "../../../../types"
import {
  AddSectionActionContainer,
  AddSectionTextContainer,
  CardAddSectionContainer,
  DividerLine,
} from "./styles"
import { useTheme } from "styled-components"

export const BindSection = ({
  formConfig,
  flowId,
  selectedSection,
}: {
  formConfig: IFormConfig
  flowId: string
  selectedSection: "employee" | "dependent"
}) => {
  const theme = useTheme()

  const navigate = useNavigate()

  return (
    <CardAddSectionContainer>
      <AddSectionTextContainer>
        <Typography
          variant="headline8"
          variantColor={theme.colors.neutral[30]}
          weight={700}
        >
          Adicionar seções ao modelo de documentos
        </Typography>

        <Typography
          variant="body4"
          weight={400}
          variantColor={theme.colors.neutral[50]}
        >
          Adicione uma seção existente ao seu modelo. Uma seção corresponde a
          uma informação, como, por exemplo, numeração da camisa. Caso a seção
          não exista, clique em criar nova seção e depois vincule na lista.
        </Typography>

        <LinkButton
          variant="secondary"
          onClick={() =>
            window.open(
              "https://beneficios.flashapp.com.br/faq/o-que-s%C3%A3o-modelos-de-documentos",
              "_blank",
            )
          }
          style={{ width: "fit-content" }}
        >
          Está com dúvidas? Acesse nossa FAQ <Icons name="IconLink" />
        </LinkButton>
      </AddSectionTextContainer>

      <DividerLine />

      <AddSectionActionContainer>
        <Button
          style={{ width: "100%" }}
          variant="primary"
          size="large"
          onClick={() =>
            navigate(
              `/flows/settings/${flowId}/documents/${formConfig._id}/create-section?selectedSection=${selectedSection}`,
            )
          }
        >
          <Icons name="IconPlus" />
          Criar nova seção
        </Button>
      </AddSectionActionContainer>
    </CardAddSectionContainer>
  )
}
