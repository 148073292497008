import { MassSignaturePage } from "src/pages/Actions/MassSignature"
import { DefaultErrorPage } from "src/pages/Errors/DefaultErrorPage"
import { FeaturesNotification } from "../components"
import {
  AddMassCandidates,
  AdmissionPage,
  CandidatePage,
  ChecklistTemplate,
  ConfigurationPage,
  ContractsTemplate,
  CreateContractsTemplate,
  CreateSectionPage,
  DocumentList,
  DocumentsPage,
  DonePage,
  EditNotification,
  EditNotificationTypeEnum,
  Hiring,
  Interview,
  ManageFieldsPage,
  ManageModelProposedLetter,
  ManageSectionsPage,
  OnHold,
  PreviewContracts,
  PreviewModelContract,
  ProposalPage,
  ProposedLetterModelHome,
  ReplaceLeader,
  RescissionPage,
  Resignation,
  ResignationArchived,
  ResignationDone,
  ResignationExam,
  ResignationSignature,
  ResignationUpdatePage,
  SelectPeople,
  SignaturePage,
  StartProcess,
  ValidationPage,
} from "../pages"
import { Notification } from "../pages/Configuration/Notification"
import { ResignationConfigurationPage } from "../pages/Configuration/ResignationConfirgurationPage"
import InitialConfigPage from "../pages/Hiring/InitialConfig"
import BenefitsPage from "../pages/Hiring/InitialConfig/Benefits"
import CardDeliveryPage from "../pages/Hiring/InitialConfig/CardDelivery"
import WorkshiftPage from "../pages/Hiring/InitialConfig/Workshift"

type RouteConfig = {
  path: string
  element: JSX.Element
  children?: Record<string, RouteConfig>
}

export const routesConfig: Record<string, RouteConfig> = {
  hiring: {
    path: "/flows/hiring",
    element: <FeaturesNotification />,
    children: {
      main: {
        path: "",
        element: <Hiring />,
      },
      admission: {
        path: "admission/:id",
        element: <AdmissionPage />,
      },
      archived: {
        path: "archived/:id",
        element: <AdmissionPage />,
      },
      candidate: {
        path: "candidate/:id",
        element: <CandidatePage />,
      },
      documents: {
        path: "documents/send/:id",
        element: <DocumentsPage />,
      },
      documentsValidation: {
        path: "documents/validation/:id",
        element: <ValidationPage />,
      },
      done: {
        path: "done/:id",
        element: <DonePage />,
      },
      proposal: {
        path: "proposal/:id",
        element: <ProposalPage />,
      },
      signature: {
        path: "signature/:id",
        element: <SignaturePage />,
      },
      initialConfig: {
        path: "initial-config/:id",
        element: <InitialConfigPage />,
      },
      workshift: {
        path: "initial-config/workshift/:id",
        element: <WorkshiftPage />,
      },
      cardDelivery: {
        path: "initial-config/card-delivery/:id",
        element: <CardDeliveryPage />,
      },
      benefits: {
        path: "initial-config/benefits/:id",
        element: <BenefitsPage />,
      },
    },
  },
  addMassCandidates: {
    path: "/flows/hiring/addMassCandidates/:flowId",
    element: <AddMassCandidates />,
  },
  resignationConfig: {
    path: "/flows/resignation/selectPeople",
    element: <ResignationConfigurationPage />,
    children: {
      selectPeople: {
        path: ":flowId",
        element: <SelectPeople />,
      },
      replaceLeader: {
        path: ":flowId/replaceLeader",
        element: <ReplaceLeader />,
      },
    },
  },
  resignation: {
    path: "/flows/resignation",
    element: <Resignation />,
  },
  //   selectPeople: {
  //     path: "/flows/resignation/selectPeople/:flowId",
  //     element: <SelectPeople />,
  //   },
  resignationOnHold: {
    path: "/flows/resignation/onHold/:id",
    element: <OnHold />,
  },
  resignationStartProcess: {
    path: "/flows/resignation/startProcess/:id",
    element: <StartProcess />,
  },
  resignationInterview: {
    path: "/flows/resignation/interview/:id",
    element: <Interview />,
  },
  resignationExam: {
    path: "/flows/resignation/exam/:id",
    element: <ResignationExam />,
  },
  resignationRescission: {
    path: "/flows/resignation/rescission/:id",
    element: <RescissionPage />,
  },
  resignationSignature: {
    path: "/flows/resignation/signature/:id",
    element: <ResignationSignature />,
  },
  resignationUpdate: {
    path: "/flows/resignation/document/update/:id",
    element: <ResignationUpdatePage />,
  },
  resignationDone: {
    path: "/flows/resignation/done/:id",
    element: <ResignationDone />,
  },
  resignationArchived: {
    path: "/flows/resignation/archived/:id",
    element: <ResignationArchived />,
  },
  settings: {
    path: "/flows/settings/:flowId",
    element: <ConfigurationPage />,
    children: {
      proposedLetter: {
        path: "proposedLetter",
        element: <ProposedLetterModelHome />,
      },
      documentsList: {
        path: "documents/list",
        element: <DocumentList />,
      },
      checklist: {
        path: "checklist",
        element: <ChecklistTemplate />,
      },
      contracts: {
        path: "contracts",
        element: <ContractsTemplate />,
      },
      notification: {
        path: "notification",
        element: <Notification />,
      },
    },
  },
  settingsCreateSection: {
    path: "/flows/settings/:flowId/documents/:id/create-section",
    element: <CreateSectionPage />,
  },
  settingsManageSections: {
    path: "/flows/settings/:flowId/documents/:id",
    element: <ManageSectionsPage />,
  },
  settingsManageFields: {
    path: "/flows/settings/:flowId/documents/:id/section/:formGroupId",
    element: <ManageFieldsPage />,
  },
  settingsCreateContracts: {
    path: "/flows/settings/:flowId/contracts/create/:step",
    element: <CreateContractsTemplate />,
  },
  settingsUpdateContracts: {
    path: "/flows/settings/:flowId/contracts/edit/:step/:modelId",
    element: <CreateContractsTemplate />,
  },
  settingsManageModelProposedLetter: {
    path: "/flows/settings/:flowId/proposedLetter/:mode/:step",
    element: <ManageModelProposedLetter />,
  },
  settingsPreviewContract: {
    path: "/flows/settings/previewContract",
    element: <PreviewContracts />,
  },
  settingsPreviewModelContract: {
    path: "/flows/settings/:flowId/proposedLetter/preview/:modelId",
    element: <PreviewModelContract />,
  },
  settingsEditNotificationMovimentation: {
    path: "/flows/settings/notification/movimentation/:flowId",
    element: <EditNotification type={EditNotificationTypeEnum.movimentation} />,
  },
  settingsEditNotificationCommentary: {
    path: "/flows/settings/notification/commentary/:flowId",
    element: <EditNotification type={EditNotificationTypeEnum.commentary} />,
  },
  settingsEditNotificationExpiration: {
    path: "/flows/settings/notification/expiration/:flowId",
    element: <EditNotification type={EditNotificationTypeEnum.expiration} />,
  },
  settingsEditNotificationProbation: {
    path: "/flows/settings/notification/probationary/:flowId",
    element: <EditNotification type={EditNotificationTypeEnum.probationary} />,
  },
  massSignature: {
    path: "/flows/actions/signing",
    element: <MassSignaturePage />,
  },
  defaultErrorPage: {
    path: "/flows/default-error-page",
    element: <DefaultErrorPage />,
  },
}

export type RoutesConfig = typeof routesConfig
