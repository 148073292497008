import { useEffect, useState } from "react"

import { Flow, ResignationCard } from "../../../types"

import { OnHoldContent } from "./OnHoldContent"
import { StartProcessContent } from "./StartProcessContent"
import { InterviewContent } from "./InterviewContent"
import { ExamContent } from "./ExamContent"
import { RescissionContent } from "./RescissionContent"
import { SignatureContent } from "./SignatureContent"
import { UpdateContractContent } from "./UpdateContractContent"

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import { Container } from "./styles"

import { GET_CONTRACT_BY_ID } from "../../../api/queries/get_contract_by_id"
import { request } from "../../../api/client"
import { useQuery } from "@tanstack/react-query"

export const ArchivedContent = ({
  card,
  flow,
}: {
  card: ResignationCard
  flow: Flow
}) => {
  const [resignationFlowInfo, setResignationFlowInfo] = useState<any>("")

  const { data: contract, isInitialLoading: isContractLoading } = useQuery(
    ["contract", card._id],
    async () => {
      const { getContractById } = await request(GET_CONTRACT_BY_ID, {
        flowCardId: card._id,
      })

      return getContractById
    },
    {
      enabled: !!card._id,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (card) setResignationFlowInfo(card)
  }, [card])

  return (
    <Container>
      {isContractLoading ? (
        <Skeleton variant="rectangular" width={"100%"} height={"200px"} />
      ) : (
        <>
          {card.employee && <OnHoldContent employee={card.employee} />}
          <StartProcessContent resignationFlowInfo={resignationFlowInfo} />
          <InterviewContent resignationFlowInfo={resignationFlowInfo} />
          <ExamContent resignationFlowInfo={resignationFlowInfo} />
          <RescissionContent resignationFlowInfo={resignationFlowInfo} />
          <SignatureContent contract={contract} />
          <UpdateContractContent resignationFlowInfo={resignationFlowInfo} />
        </>
      )}
    </Container>
  )
}
